export const FormActionTypes = {
    //Form Action Types
    SET_LOAN: "SET_LOAN",
    SET_COMPANY: "SET_COMPANY",
    SET_TURNOVER: "SET_TURNOVER",
    SET_GOALS: "SET_GOALS",
    SET_CLIENTS: "SET_CLIENTS",
    SET_EMAIL: "SET_EMAIL",
    SET_NAME: "SET_NAME",
    SET_SURNAME: "SET_SURNAME",
    SET_PHONE: "SET_PHONE",
    GET_COMPANY_DATA: "GET_COMPANY_DATA",
    LOAD_KVK: "LOAD_KVK",
};  