//react
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
//mui
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
//actions
import {
  SendfullState,
  setName,
  setPhone,
  setSurname,
} from "../../services/Form/FormActions";
//Form builders & validators
import { useFormik } from "formik";
import * as yup from "yup";
//styles
import "./Styles.css";

export const Step7 = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  //Selecting useful bits of state:
  const [logo, setLogo] = useState();
  const [credit, setCredit] = useState();
  const [loan, setLoan] = useState();
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [purpose, setPurpose] = useState();
  const [duration, setDuration] = useState();
  const [rate, setRate] = useState();
  const [error, setError] = useState(false);

  //Local state and setters:
  const [name, setNameVal] = useState("");
  const [surname, setSurnameVal] = useState("");
  const [phone, setPhoneVal] = useState("");

  //navigation
  const location = useLocation();
  // console.log(location.state);
  useEffect(() => {
    if (location.state !== "fromStep6") {
      //should only be accessible from previous step
      Navigate(-1);
    }
  }, []);
  let locState = "fromStep7";

  //form builders and validators
  const validationSchema = yup.object({
    name: yup
      .string("Je voornaam is vereist.")
      .matches(/^[aA-zZ\s]+$/, "Vul een echte voornaam in.")
      .required("Je voornaam is vereist."),
    surname: yup
      .string("Je achternaam hebben we nodig.")
      .matches(/^[aA-zZ\s]+$/, "Vul een echte achternaam in.")
      .required("Je achternaam hebben we nodig."),
    phone: yup
      .number()
      .typeError("Vul een geldig telefoonnummer in.")
      .required("Je telefoonnummer is vereist."),
  });

  const formik = useFormik({
    initialValues: {
      name,
      surname,
      phone,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(setName(name));
      dispatch(setSurname(surname));
      dispatch(setPhone(phone));
      Navigate("../step/9", { state: locState });
    },
  });

  //State Management:
  useEffect(() => {
    const data = window.sessionStorage.getItem("name");
    const data2 = window.sessionStorage.getItem("surname");
    const data3 = window.sessionStorage.getItem("phone");
    //Remember form values on refresh and navigation.

    formik.setFieldValue("name", data && data !== "null" ? data : "");
    formik.setFieldValue("surname", data2 && data2 !== "null" ? data2 : "");
    formik.setFieldValue("phone", data3 && data3 !== "null" ? data3 : "");
    //setting state slices to input values
    setNameVal(data);
    setSurnameVal(data2);
    setPhoneVal(data3);
    const d = JSON.parse(window.sessionStorage.getItem("offer"));
    console.log(d);
    setLogo(d.logo);
    setLoan(window.sessionStorage.getItem("amount"));
    setPurpose(window.sessionStorage.getItem("goals"));
    setCredit(d.credit);
    setMin(d.minimum_loan);
    setMax(d.maximum_loan);
    setDuration(d.max_duration);
    setRate(d.loan_rate_indication);
  }, []);
  useEffect(() => {
    window.sessionStorage.setItem("name", name);
    window.sessionStorage.setItem("surname", surname);
    window.sessionStorage.setItem("phone", phone);
  }, [name, surname, phone]);

  // console.log("hello!", name);
  // console.log("getItem", window.sessionStorage.getItem("name"));

  //To make sure that state is persistant on refresh.
  useEffect(() => {
    dispatch(setName(window.sessionStorage.getItem("name")));
    dispatch(setSurname(window.sessionStorage.getItem("surname")));
    dispatch(setPhone(window.sessionStorage.getItem("phone")));
  }, [name, surname, phone]);

  //event handling
  const handleClickOpen = () => {
    formik.handleSubmit();
  };
  const handleThisName = (e) => {
    e.preventDefault();
    setNameVal(e.target.value);
  };
  const handleThisSurName = (e) => {
    e.preventDefault();
    setSurnameVal(e.target.value);
  };
  const handleThisPhone = (e) => {
    e.preventDefault();
    setPhoneVal(e.target.value);
  };
  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };
  const handleChecked = (e) => {
    e.preventDefault();
    setError(e.target.checked);
  };
  //sending state data to server
  const fullState = useSelector((state) => state);
  // console.log("my full state is : ", fullState);
  const sendAllData = () => {
    SendfullState(fullState);
  };

  return (
    <Grid
      container
      direction="row"
      // marginTop="60px"
    >
      <div className="back-btn" onClick={onBack}>
        <IconButton>
          <ArrowBackIos />
        </IconButton>
      </div>
      <Grid
        container
        direction="row"
        marginBottom="20px"
        justifyContent="center"
        className="view"
        sx={{ marginLeft: "40px" }}
      >
        <Card
          className="formCard"
          sx={{
            justifyContent: "center",
            backgroundColor: "#F7F7F7",
            boxShadow: "none",
            // minWidth: 400,
            marginLeft: "15px",
          }}
        >
          <div style={{ width: "220px" }}>
            <Typography
              component="div"
              variant="h6"
              fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
              sx={{
                marginTop: "6px",
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              Aanbod
            </Typography>
            <Card
              sx={{
                border: 2,
                borderColor: "lightblue",
                borderRadius: 1,
                boxShadow: "none",
                height: "auto",
                backgroundColor: "#F7F7F7",
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  border: 1,
                  borderColor: "darkgray",
                  borderRadius: 1,
                  marginLeft: "8px",
                  marginTop: "6px",
                }}
                image={logo}
                alt="credit logo"
                style={{ height: "50px", width: "80px" }}
              />
              <div>
                <Typography
                  component="div"
                  fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                  sx={{
                    marginLeft: "9px",
                    marginTop: "6px",
                    fontSize: 15,
                    fontWeight: 700,
                  }}
                >
                  {credit}
                </Typography>
                <Typography
                  component="div"
                  variant="h6"
                  fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                  sx={{
                    marginLeft: "9px",
                    fontSize: 13,
                  }}
                >
                  Zakelijke Lening
                </Typography>
                <Divider light />
                <Typography
                  component="div"
                  variant="h6"
                  fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                  sx={{
                    marginTop: "6px",
                    marginLeft: "9px",
                    fontSize: 13,
                  }}
                >
                  <strong>doel:</strong> {purpose}
                </Typography>
                <Typography
                  component="div"
                  variant="subtitle"
                  sx={{
                    marginTop: "6px",
                    marginLeft: "9px",
                    fontSize: 13,
                  }}
                >
                  {/* Amount distributed */}
                  <strong
                    style={{
                      fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                    }}
                  >
                    Bedrag: €
                  </strong>{" "}
                  {loan}
                </Typography>
                <Typography
                  component="div"
                  variant="subtitle"
                  sx={{
                    marginTop: "6px",
                    marginLeft: "9px",
                    fontSize: 13,
                  }}
                >
                  <strong
                    style={{
                      fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                    }}
                  >
                    Maximale looptijd:
                  </strong>{" "}
                  {duration} jaar
                </Typography>
                <Typography
                  component="div"
                  variant="subtitle"
                  sx={{
                    marginTop: "6px",
                    marginLeft: "9px",
                    fontSize: 13,
                  }}
                >
                  <strong
                    style={{
                      fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                    }}
                  >
                    minimale lening: €
                  </strong>{" "}
                  {min}
                </Typography>
                <Typography
                  component="div"
                  variant="subtitle"
                  sx={{
                    marginTop: "6px",
                    marginLeft: "9px",
                    fontSize: 13,
                  }}
                >
                  <strong
                    style={{
                      fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                    }}
                  >
                    maximale lening: €
                  </strong>{" "}
                  {max}
                </Typography>
                <Typography
                  component="div"
                  variant="subtitle"
                  sx={{
                    marginTop: "6px",
                    marginLeft: "9px",
                    fontSize: 13,
                    marginBottom: "6px",
                  }}
                >
                  <strong
                    style={{
                      fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                    }}
                  >
                    rente p.j:
                  </strong>{" "}
                  {rate}
                </Typography>
              </div>
            </Card>
          </div>
          <div style={{ width: "300px", marginTop: "20px" }}>
            <Typography
              component="div"
              variant="h6"
              sx={{
                marginLeft: "10px",
                fontSize: 20,
                color: "#5cbfc3",
                fontFamily: "Lato, Helvetica Neue Arial, sans-serif",
                fontWeight: 500,
              }}
            >
              Mijn contactgegevens:
            </Typography>

            <Box className="app-form">
              <FormControl
                variant="filled"
                className="centerMyForm"
                margin="dense"
              >
                <TextField
                  sx={{ marginBottom: "10px" }}
                  required
                  variant="filled"
                  id="name"
                  label="Voornaam"
                  size="small"
                  data-testid="name"
                  value={formik.values.name}
                  onInput={handleThisName}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />

                <TextField
                  sx={{ marginBottom: "10px" }}
                  required
                  variant="filled"
                  id="surname"
                  size="small"
                  data-testid="surname"
                  value={formik.values.surname}
                  onInput={handleThisSurName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.surname && Boolean(formik.errors.surname)
                  }
                  helperText={formik.touched.surname && formik.errors.surname}
                  label="Achternaam"
                />
                <TextField
                  required
                  variant="filled"
                  id="phone"
                  size="small"
                  data-testid="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onInput={handleThisPhone}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  label="Telefoonnummer"
                  placeholder="+33 12347968"
                />
                <br />
                <FormControlLabel
                  className="checkbox"
                  style={{
                    fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                  }}
                  label={
                    <Typography fontFamily=" Lato, Helvetica Neue Arial, sans-serif">
                      Ja, ik ga akkoord met het verwerken van mijn gegevens. De
                      aanbieder en bankenvergelijking mogen mij benaderen per
                      telefoon of email.
                    </Typography>
                  }
                  control={
                    <Checkbox onChange={handleChecked} type="checkbox" />
                  }
                  id="checkb1"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.checkb1 && Boolean(formik.errors.checkb1)
                  }
                  helperText={formik.touched.checkb1 && formik.errors.checkb1}
                />

                <br />

                <Button
                  sx={{
                    marginLeft: "10px",
                    alignItems: "center",
                    marginBottom: "7px",
                    marginRight: "10px",
                    justifyContent: "10px",
                    width: "90%",
                  }}
                  variant="contained"
                  onClick={() => {
                    sendAllData();
                    handleClickOpen();
                  }}
                  disabled={!error}
                  data-testid="nextButton"
                >
                  Bevestig
                </Button>
                <Typography
                  component="div"
                  variant="h6"
                  fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                  sx={{
                    color: "#1976D2",
                    marginLeft: "25px",
                    marginTop: "-2px",
                    fontSize: 13,
                  }}
                >
                  Zonder verdere verplichtingen
                </Typography>
              </FormControl>
            </Box>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};
