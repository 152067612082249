//importing action types
import { FormActionTypes } from "./formActionTypes";

const initialState = {
    //Form state
    Amount: "",
    CompanyName: "",
    turnover: "",
    Goals: "",
    Clients: "",
    Email: "",
    Phone: "",
    Name: "",
    Surname: "",
    kvk: "",

}

export const InfoReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FormActionTypes.SET_LOAN:
            return { ...state, Amount: payload };

        case FormActionTypes.SET_COMPANY:
            return { ...state, CompanyName: payload };
        case FormActionTypes.SET_TURNOVER:
            return { ...state, turnover: payload };

        case FormActionTypes.SET_GOALS:
            return { ...state, Goals: payload };

        case FormActionTypes.SET_CLIENTS:
            return { ...state, Clients: payload };

        case FormActionTypes.SET_EMAIL:
            return { ...state, Email: payload };

        case FormActionTypes.SET_PHONE:
            return { ...state, Phone: payload };

        case FormActionTypes.SET_NAME:
            return { ...state, Name: payload };

        case FormActionTypes.SET_SURNAME:
            return { ...state, Surname: payload };
        case FormActionTypes.GET_COMPANY_DATA:
            return { ...state, kvk: payload };
        case FormActionTypes.LOAD_KVK:
            return { ...state, payload };
        default:
            return state;

    }
}