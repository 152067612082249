import { Step1 } from '../components/Step/Step1';
import { Step2 } from '../components/Step/Step2';
import { Step3 } from '../components/Step/Step3';
import { Step4 } from '../components/Step/Step4';
import { Step5 } from '../components/Step/Step5';
import { Step6 } from '../components/Step/Step6';
import { Step7 } from '../components/Step/Step7';
import { TurnOver } from '../components/Step/TurnOver';
import { Login } from '../components/Step/Login';
import { Provider } from "../components/Provider";
import { Home } from '../components/Step/Home';
import { HeaderContext } from '../views/HeaderContext';
import { Thanks } from '../components/Step/Thanks';
import { useRoutes, Navigate } from 'react-router-dom';
import { Step22 } from '../components/Step/Step22';

export default function Router() {
    let element = useRoutes([
        {
            element: <Home />,
            path: "/",

        },

        {
            element: <Navigate replace to="/" />,
            name: "home",
            path: "/",
        },

        {
            element: <HeaderContext />,
            name: "Multi step Form",
            children: [
                {
                    element: <Login />,
                    path: "/login",
                    name: "login page"
                },
                {
                    name:"First step",
                    path: "step/1",
                    element: <Step1 />
                },
                {
                    name:"Second step",
                    path: "step/2",
                    element: <Step2 />
                },
                {
                    name:"turnover step",
                    path: "step/3",
                    element: <TurnOver />
                },
                {
                    name:"company age step",
                    path: "step/2.2",
                    element:<Step22/>
                },
                {
                    name:"Third step",
                    path: "step/4",
                    element: <Step3 />
                },
                {
                    name:"Fourth step",
                    path: "step/5",
                    element: <Step4 />
                },
                {
                    name:"Fifth step",
                    path: "step/6",
                    element: <Step5 />
                },
                {
                    name:"Providers list",
                    path: "step/7",
                    element: <Step6 />
                },
                {
                    name:"Seventh step",
                    path: "step/8",
                    element: <Step7 />
                },
                {
                    name:"Thank You page",
                    path: "step/9",
                    element: <Thanks />
                },
            ]
        },

    ])

    return element;
}