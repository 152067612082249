//Importing Action types:
import { CardActionTypes } from "./cardActionTypes";

//Actions or Events:
export const setLogo=(logo)=>{
    return {
        type: CardActionTypes.SET_LOGO,
        payload: logo
    };
};

export const setCredit=(credit)=>{
    return {
        type: CardActionTypes.SET_CREDIT,
        payload: credit
    };
};

export const setProposedLoan=(ploan)=>{
    return {
        type: CardActionTypes.SET_PROPOSED_LOAN,
        payload: ploan
    };
};
export const setDispending=(dispensing)=>{
    return {
        type: CardActionTypes.SET_DISPENSING,
        payload: dispensing
    };
};
export const setDuration=(duration)=>{
    return {
        type: CardActionTypes.SET_DURATION,
        payload: duration
    };
};
export const setSelectedLogo=(logo)=>{
    return {
        type: CardActionTypes.SET_SELECTEDLOGO,
        payload: logo
    };
}
export const setMinL=(min)=>{
    return {
        type: CardActionTypes.SET_MINL,
        payload: min
    }
}
export const setMaxL=(max)=>{
    return {
        type: CardActionTypes.SET_MAXL,
        payload: max
    }
}
export const setRate=(rate)=>{
    return {
        type: CardActionTypes.SET_RATE,
        payload: rate
    }
}
export const setOffer=(selectedLogo, selectedCredit,minimum_loan,
    maximum_loan,loan_rate_indication, max_duration )=>{
    return {
        type: CardActionTypes.SET_SELECTEDINFO,
        payload: {selectedLogo, selectedCredit, minimum_loan,
            maximum_loan,loan_rate_indication, max_duration}
    }
}
