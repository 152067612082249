//React
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
//MUI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
//Styles
import './Styles.css';
//Form builders & validators
import { useFormik } from 'formik';
import * as yup from 'yup';
//actions
import { setGoals } from '../../services/Form/FormActions';


export const Step3 = () => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [goals, setGoalsValue] = useState("none");

    //navigation
    const location = useLocation();
    // console.log(location.state);
    useEffect(() => {
        if (location.state !== 'fromTurnOver') {
            //step2 should only be accessible if i come from step1!
            //otherwise, go back to step1.
            Navigate(-1)
        }
    }, []);
    let locState = "fromStep3"

    //validators and form building
    const validationSchema = yup.object({
        goals: yup
            .string('selecteer uw doelen')
            .required('selecteer uw doelen.')
    })

    const formik = useFormik({
        initialValues: {
            goals,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(setGoals(goals));
            Navigate("../step/5", { state: locState })
        },
    })

    //State Management:
    useEffect(() => {

        const data = window.sessionStorage.getItem("goals");
        if (data) {
            formik.setFieldValue("goals", data)
            setGoalsValue(data);
            // console.log("goals name after get", data);
        }


    }, []);
    useEffect(() => {
        window.sessionStorage.setItem("goals", goals)
        formik.setFieldValue("goals", window.sessionStorage.getItem("goals"))
    }, [goals]);

    // console.log("hello!", goals);
    // console.log("getItem", window.sessionStorage.getItem("goals"));

    //To make sure that state is persistant on refresh.
    useEffect(() => {
        dispatch(setGoals(goals));

    }, [goals])


    //event handling
    const Next = (e) => {
        e.preventDefault()
        formik.handleSubmit()
    }

    const handleThis = (e) => {

        setGoalsValue(e.target.value)
    }
    // console.log(goals);

    const onBack = (e) => {
        e.preventDefault()
        Navigate(-1)
    }
    return (
        <Grid container
            direction="column"
            marginTop="60px"
        >
            <Box>
                <div className='back-btn' onClick={onBack}>
                    <IconButton  ><ArrowBackIos /></IconButton>
                </div>
                <Card sx={{
                    minHeight: '250px',
                    minWidth: '300px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: 'none',
                    flexDirection: 'column',
                    backgroundColor: '#F7F7F7'

                }}>
                    <React.Fragment>
                        <CardContent >
                            <div>

                                <FormControl variant='filled' className='centerMyForm'>
                                    <Typography
                                        component="div"
                                        variant='h3'
                                        sx={{
                                            fontWeight: 500,
                                            fontFamily: "Merriweather  serif",
                                            fontSize: '2.1rem',
                                            lineHeight: '36px',
                                            textAlign: 'center',
                                            marginBottom: "30px"


                                        }}
                                    >
                                        Wat is je leendoel?
                                    </Typography>
                                    <FormControl variant='filled' className='centerMyForm' >
                                        <InputLabel id="droplabel" >Doelen</InputLabel>
                                        <Select
                                            labelId="droplabel"
                                            native
                                            id='goals'
                                            name='goals'
                                            sx={{ width: "320px" }}
                                            // data-testid="goals"

                                            value={formik.values.goals}
                                            onInput={handleThis}
                                            onChange={formik.handleChange}
                                            error={formik.touched.goals && Boolean(formik.errors.goals)}
                                        //helperText={formik.touched.goals && formik.errors.goals}

                                        >
                                            <option value="none" selected disabled hidden></option>
                                            <option value="groei en uitbreiding">groei en uitbreiding</option>
                                            <option value="bedrijfsovername">bedrijfsovername</option>
                                            <option value="voorraden en debiteuren">voorraden en debiteuren</option>
                                            <option value="machines">machines</option>
                                            <option value="inventaris">inventaris</option>
                                            <option value="voertuigen">voertuigen</option>
                                            <option value="werkkapitaal">werkkapitaal</option>
                                            <option value="herfinancieren (geen hypotheek)">herfinancieren (geen hypotheek)</option>
                                            <option value="Vastgoedfinanciering ">Vastgoedfinanciering </option>



                                        </Select> <br />
                                        <div className='buttonbox'>
                                            <Button variant="contained" type='submit' size="large"
                                                className='button'
                                                disabled={goals=="none"}
                                                data-testid="nextButton"
                                                onClick={Next}  >Verder</Button>
                                        </div>
                                    </FormControl>
                                </FormControl>
                            </div>
                        </CardContent>
                    </React.Fragment>
                </Card>
            </Box>
        </Grid>
    );

}