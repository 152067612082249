//React
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
//MUI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
//Form builders & validators
import { useFormik } from 'formik';
import * as yup from 'yup';
//actions
import { setClients } from '../../services/Form/FormActions';
//styles
import './Styles.css';

export const Step4 = () => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [clients, setClientValue] = useState("none");

    //navigation
    const location = useLocation();
    // console.log(location.state);
    useEffect(() => {
        if (location.state !== 'fromStep3') {
            //step2 should only be accessible if i come from step1!
            //otherwise, go back to step1.
            Navigate(-1)
        }
    }, []);
    let locState = "fromStep4"

    //form builder and validators
    const validationSchema = yup.object({
        clients: yup
            .string('selecteer uw klanten ')
            .required('selecteer uw klanten ')
    })
    const formik = useFormik({
        initialValues: {
            clients,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(setClients(clients))
            Navigate("../step/6", { state: locState })
        },
    })

    //State Management:
    useEffect(() => {
        const data = window.sessionStorage.getItem("clients");
        if(data){
            formik.setFieldValue("clients", data)
        setClientValue(data);
        // console.log("clients after get", data);
        }
        

    }, []);
    useEffect(() => {
        window.sessionStorage.setItem("clients", clients)
        formik.setFieldValue("clients", window.sessionStorage.getItem("clients"))

    }, [clients]);

    // console.log("hello!", clients);
    // console.log("getItem", window.sessionStorage.getItem("clients"));

    //To make sure that state is persistant on refresh.
    useEffect(() => {
        dispatch(setClients(window.sessionStorage.getItem("clients")));

    }, [clients])
    //event handling
    const Next = (e) => {
        e.preventDefault()
        formik.handleSubmit()
    }
    const handleThis = (e) => {
        e.preventDefault()
        setClientValue(e.target.value)
    }
    // console.log("clients after useState", clients);

    const onBack = (e) => {
        e.preventDefault()
        Navigate(-1)
    }
    return (
        <Grid container
            direction="column"
            marginTop="60px"
        >
            <Box>
                <div className='back-btn' onClick={onBack}>
                    <IconButton  ><ArrowBackIos /></IconButton>
                </div>
                <Card sx={{
                    minHeight: '250px',
                    minWidth: '300px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: 'none',
                    flexDirection: 'column',
                    backgroundColor: '#F7F7F7'

                }} >
                    <React.Fragment>
                        <CardContent >
                            <div>
                                <FormControl variant='filled' className='centerMyForm'>
                                    <Typography
                                        component="div"
                                        variant='h3'
                                        sx={{
                                            fontWeight: 500,
                                            fontFamily: "Merriweather  serif",
                                            fontSize: '2.1rem',
                                            lineHeight: '36px',
                                            textAlign: 'center',
                                            marginBottom: "30px"

                                        }}
                                    >
                                        Wat voor type klanten bedien je?                                    
                                    </Typography>
                                    <FormControl variant='filled' className='centerMyForm'  >
                                        <InputLabel id="demo-simple-select-standard-label">klanten</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            label="Clients"
                                            native
                                            id='clients'
                                            sx={{ width: "320px" }}

                                            onInput={handleThis}

                                            value={formik.values.clients}
                                            onChange={formik.handleChange}
                                            error={formik.touched.clients && Boolean(formik.errors.clients)}
                                        >
                                            <option value="none" selected disabled hidden></option>
                                            <option value="consument" >consument</option>
                                            <option value="bedrijven">bedrijven</option>
                                            <option value=" overheid">overheid of non-profit</option>
                                        </Select>
                                        <br />
                                        <div className='buttonbox'>
                                            <Button variant="contained"
                                                type='submit'
                                                size="large"
                                                className='button'
                                                disabled={clients=="none"}
                                                data-testid="nextButton"
                                                onClick={Next}  >Verder</Button>
                                        </div>
                                    </FormControl>
                                </FormControl>
                            </div>

                        </CardContent>
                    </React.Fragment>
                </Card>
            </Box>
        </Grid>
    );

}