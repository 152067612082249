import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//MUI
import { TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";
//Form builders & validators
import { useFormik } from "formik";
import * as yup from "yup";
//Styles
import "./Styles.css";

export const Step22 = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [age, setAge] = useState("");

  const location = useLocation();
  useEffect(() => {
    if (location.state !== "fromStep2") {
      //step2 should only be accessible if i come from step1!
      //otherwise, go back to step1.
      Navigate(-1);
    }
  }, []);

  let locState = "fromStep2";
  //Event handling
  const Next = (choice) => () => {
    window.sessionStorage.setItem("age", choice);
    Navigate("../step/3", { state: locState });
  };

  // const handleThis = (e) => {
  //     e.preventDefault()
  //     onChangeHandler(e.target.value)
  //     setCompanyN(e.target.value)
  // }

  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };
  return (
    <Grid container direction="column" width="100%" marginTop="60px">
      <Box>
        <div className="back-btn" onClick={onBack}>
          <IconButton>
            <ArrowBackIos />
          </IconButton>
        </div>
        <Card
          className="bod"
          sx={{
            minHeight: "250px",
            minWidth: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            backgroundColor: "#F7F7F7",
          }}
        >
          <React.Fragment>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  component="div"
                  variant="h3"
                  sx={{
                    fontWeight: 500,
                    fontFamily: "Merriweather  serif",
                    fontSize: "2.1rem",
                    lineHeight: "36px",
                    textAlign: "center",
                    marginBottom: "25px",
                  }}
                >
                  Hoe lang bestaat je bedrijf?
                </Typography>

                <Grid
                  container
                  rowSpacing={1}
                  sx={{ maxWidth: "500px" }}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <Card
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70px",
                        "&:hover": {
                          backgroundColor: "gray",
                          cursor: "pointer",
                        },
                      }}
                      onClick={Next(0)}
                    >
                      Nog niet gestart
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70px",
                        "&:hover": {
                          backgroundColor: "gray",
                          cursor: "pointer",
                        },
                      }}
                      onClick={Next(1)}
                    >
                      Korter dan 6 maanden
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70px",
                        textAlign:"center",
                        "&:hover": {
                          backgroundColor: "gray",
                          cursor: "pointer",
                        },
                      }}
                      onClick={Next(2)}
                    >
                      Tussen 6 en 12 maanden
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70px",
                        "&:hover": {
                          backgroundColor: "gray",
                          cursor: "pointer",
                        },
                      }}
                      onClick={Next(3)}
                    >
                      Tussen 1 en 2 jaar
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70px",
                        "&:hover": {
                          backgroundColor: "gray",
                          cursor: "pointer",
                        },
                      }}
                      onClick={Next(4)}
                    >
                      Langer dan 2 jaar
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </CardContent>
          </React.Fragment>
        </Card>
      </Box>
    </Grid>
  );
};
