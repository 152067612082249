//react
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//styles
import "./Styles.css";
//mui
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Typography } from "@mui/material";
//reusable component
import { Provider } from "../Provider";
import { getFilteredProducts, getFilteredProductsAge } from "../../services/Form/FormActions";
import { useSelector } from "react-redux";

export const Step6 = () => {
  const Navigate = useNavigate();
  //navigation
  const location = useLocation();
  const LS = location.state;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const amount = window.sessionStorage.getItem("amount");
  const turnover = window.sessionStorage.getItem("turno");
  const kvk = JSON.parse(window.sessionStorage.getItem("kvkData"));
  // console.log(kvk);
  const getData = async () => {
    setLoading(true);
    try {
      let age=window.sessionStorage.getItem("age");
      let result;
      if(age&&age!=""&&age!="null"&&age!="undefined"){
          result=await getFilteredProductsAge(age)
      }
      else{
          result = await getFilteredProducts();
      } 
      // console.log(result);
      setData(result);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (amount < error.minL) {
        setError("The amount you entered is too small.");
      } else if (amount > error.maxL) {
        setError("The amount you entered is too big.");
      } else if (turnover < error.minTo) {
        setError("Your turnover is too small.");

      }else if((!kvk.payload.materieleRegistratie.datumAanvang) && !(kvk.payload.formeleRegistratiedatum)){
          setError("Your company is either unregistered, or its age is too small.")
      }
       else {
        setError(" 'Vastgoedfinanciering' is not financiable at the moment ");
      }
    }
  };
  useEffect(() => {
    if (LS !== "fromStep5or8") {
      //come only from previous step
      Navigate(-1);
    }
    getData();
  }, []);

  //event handling
  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };

  return (
    <Grid container direction="row">
      <div className="back-btn" onClick={onBack}>
        <IconButton>
          <ArrowBackIos />
        </IconButton>
      </div>
      <Grid
        container
        direction="column"
        // alignItems="center"
        // justifyContent="center"
        marginTop="60px"
        className="title"
      >
        <Typography
          component="div"
          variant="h5"
          sx={{
            fontWeight: 500,
            fontFamily: "Merriweather  serif",
            fontSize: "2rem",
            lineHeight: "36px",
            textAlign: "center",

            marginBottom: "20px",
          }}
        >
          We hebben de volgende leningen voor jou gevonden:
        </Typography>

        {!error && data.length !== 0 ? (
          data.map((product, index) => {
            return (
              <Provider
                key={index}
                logo={product.product_img}
                credit={product.product}
                minimum_loan={product.minimum_loan}
                maximum_loan={product.maximum_loan}
                loan_rate_indication={product.loan_rate_indication}
                max_duration={product.max_duration}
              />
            );
          })
        ) : (
          <Typography
            component="div"
            variant="h5"
            color="darkred"
            sx={{
              fontWeight: 500,
              fontFamily: "Merriweather  serif",
              fontSize: "2rem",
              lineHeight: "36px",
              textAlign: "left",
              marginLeft: "20%",
              marginBottom: "20px",
            }}
          >
            {" "}
            {error}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
