//React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//MUI
import { TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";
//Form builders & validators
import { useFormik } from "formik";
import * as yup from "yup";
//Actions
import {
  getCompanyData,
  loadKvk,
  setCompany,
  setKvk,
} from "../../services/Form/FormActions";
//Styles
import "./Styles.css";

export const Step2 = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [company, setCompanyN] = useState("");

  //Using API
  const [users, setUsers] = useState([]);
  const [text, setText] = useState("");
  const [textkvk, setTextkvk] = useState(""); //KVK number.
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionskvk, setSuggestionskvk] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const API_KEY_DEV = process.env.REACT_APP_OPENKVK_API_KEY;
  const [kvkData, setKvkData] = useState({});
  const [hasRegister, setHasRegister] = useState(true);

  //navigation
  const location = useLocation();
  // console.log(location.state);
  useEffect(() => {
    if (location.state !== "fromStep1") {
      //step2 should only be accessible if i come from step1!
      //otherwise, go back to step1.
      Navigate(-1);
    }
  }, []);

  let locState = "fromStep2";

  //form building and validators
  const validationSchema = yup.object().shape({
    company: yup.string().required("We hebben je bedrijfsnaam nodig."),
  });
  const formik = useFormik({
    initialValues: {
      company: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(setCompany(company));
      let d = JSON.parse(window.sessionStorage.getItem("kvkData"));
      // let regDate=d.payload.payload.formeleRegistratiedatum

      if(d && (d.payload.payload.formeleRegistratiedatum || d.payload.payload.materieleRegistratie.datumAanvang)){
          window.sessionStorage.removeItem("age")
          Navigate("../step/3", { state: locState });
      }else {
          Navigate("../step/2.2", { state: locState })
      }
      
    },
  });
  //State Management:
  useEffect(() => {
    const data = window.sessionStorage.getItem("company");
    formik.setFieldValue(
      "company",
      data && data !== "null" && data !== "undefined" ? data : ""
    );
    setCompanyN(data && data !== "null" && data !== "undefined" ? data : "");
    let data2 = JSON.parse(window.sessionStorage.getItem("kvkData"));
    if (data2 && Object.keys(data2).length != 0) {
      // console.log(data2);
      dispatch(data2);
    }
    // console.log("company name after get", data);
  }, []);
  useEffect(() => {
    window.sessionStorage.setItem("company", company);
    if (kvkData && Object.keys(kvkData).length !== 0) {
      window.sessionStorage.setItem("kvkData", JSON.stringify(kvkData));
    }
    dispatch(setCompany(company));
    // console.log("**********", kvkData);
  }, [company, kvkData]);

  // console.log("hello!", company);
  // console.log("getItem", window.sessionStorage.getItem("company"));

  //To make sure that state is persistant on refresh.
  //handling events
  const Next = () => {
    formik.handleSubmit();
  };

  const handleThis = (e) => {
    e.preventDefault();
    onChangeHandler(e.target.value);
    setCompanyN(e.target.value);
  };

  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };
  //Autocomplete event handling:
  const onSuggestHandler = (company) => {
    setCompanyN(company);
    formik.setFieldValue("company", company);
    setSuggestions([]);
  };

  const onSuggestHandlerkvk = (textkvk) => {
    setTextkvk(textkvk);
    // console.log("textKvk please:????", textkvk);
    setSuggestionskvk([]);
  };

  const suggestUsers = async (text, apikey) => {
    try {
      const users = await loadKvk(text, apikey);
      setUsers(users.handelsnaam);
      // console.log(users);
    } catch (error) {
      console.log(error);
    }
  };
  const onChangeHandler = (text) => {
    let matches = [];
    if (text.length > 0) {
      matches = users.filter((user) => {
        const regex = new RegExp(`${text}`, "gi");
        return user.handelsnaam.match(regex);
      });
    }
    setSuggestions(matches);
    setCompanyN(text);
    suggestUsers(text, API_KEY_DEV);
  };

  //sending kvk number to server & getting company info.
  // console.log("company and kvkno are: ", company, textkvk);
  const sendData = async (textkvk) => {
    try {
      let res = await getCompanyData(textkvk, dispatch);
      setDisableButton(false);
      // console.log(",,,,,,", res);
      setKvkData((prev) => {
        // console.log(prev);
        return res;
      });
      if (!res.payload.materieleRegistratie.datumAanvang) {
        setHasRegister(false);
      }
    } catch (error) {
      console.log("couldn't get company data");
    }
  };

  return (
    <Grid container direction="column" width="100%" marginTop="60px">
      <Box>
        <div className="back-btn" onClick={onBack}>
          <IconButton>
            <ArrowBackIos />
          </IconButton>
        </div>
        <Card
          className="bod"
          sx={{
            minHeight: "250px",
            minWidth: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            backgroundColor: "#F7F7F7",
          }}
        >
          <React.Fragment>
            <CardContent>
              <FormControl className="centerMyForm">
                <Typography
                  component="div"
                  variant="h3"
                  sx={{
                    fontWeight: 500,
                    fontFamily: "Merriweather  serif",
                    fontSize: "2.1rem",
                    lineHeight: "36px",
                    textAlign: "center",
                    marginBottom: "25px",
                  }}
                >
                  Wat is je bedrijfsnaam?
                </Typography>
                <Grid
                  container
                  direction="column"
                  width="320px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <TextField
                    id="company"
                    name="company"
                    label="bedrijfsnaam"
                    variant="filled"
                    sx={{ width: "320px" }}
                    data-testid="companyName"
                    value={formik.values.company}
                    onInput={handleThis}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.company && Boolean(formik.errors.company)
                    }
                    helperText={formik.touched.company && formik.errors.company}
                  />

                  <div className="dropdown">
                    {suggestions &&
                      suggestions.map((suggestion, i) => (
                        <div
                          key={i}
                          className="suggestion col-md-40  justify-content-md-center"
                          onClick={() => {
                            onSuggestHandler(suggestion.handelsnaam);
                            onSuggestHandlerkvk(suggestion.dossiernummer);
                            // sendData(suggestion.dossiernummer);
                          }}
                        >
                          <Typography sx={{ width: "315px" }}>
                            {suggestion.handelsnaam}
                          </Typography>
                          <Typography sx={{ width: "315px" }}>
                            {suggestion.plaats}
                          </Typography>
                          <Typography sx={{ width: "315px" }}>
                            {suggestion.dossiernummer}
                          </Typography>
                          <Typography sx={{ width: "315px" }}>
                            {suggestion.straatnaam}
                          </Typography>
                          <Typography sx={{ width: "315px" }}>
                            {suggestion.kvkNummer}
                          </Typography>
                        </div>
                      ))}
                  </div>
                </Grid>{" "}
                <br />
                <div className="buttonbox">
                  <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    className="button"
                    data-testid="nextButton"
                    // disabled={!hasRegister}
                    onClick={async (e) => {
                      await sendData(textkvk);
                      Next(e);
                    }}
                  >
                    Verder
                  </Button>
                </div>
              </FormControl>
            </CardContent>
          </React.Fragment>
        </Card>
      </Box>
    </Grid>
  );
};
