//react
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
//mui
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
//Form builders & validators
import { useFormik } from 'formik';
import * as yup from 'yup';
//actions
import { SendfullState, SendStateToCompare, setEmail } from '../../services/Form/FormActions';
//styles
import './Styles.css';

export const Step5 = () => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [email, setEmailVal] = useState();

    //navigation
    const location = useLocation();
    // console.log(location.state);
    useEffect(() => {
        if (location.state !== 'fromStep4') {
            //step2 should only be accessible if i come from step1!
            //otherwise, go back to step1.
            Navigate(-1)
        }
    }, []);
    let locState = "fromStep5or8"

    //form building and validators
    const validationSchema = yup.object({
        email: yup
            .string('Vul een geldig e-mailadres in.')
            .email('Vul een geldig e-mailadres in.')
            .required('We hebben je e-mailadres nodig.'),

    })

    const formik = useFormik({
        initialValues: {
            email,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // alert(JSON.stringify(values, null, 2)); 
            dispatch(setEmail(email))
            // dispatch(EnterPhone(document.getElementById('phone').value))
            Navigate("../step/7", { state: locState })
        },
    })
    //State Management:
    useEffect(() => {
        const data = window.sessionStorage.getItem("email");
        formik.setFieldValue("email", data&&data!=="null" ?data:"")
        setEmailVal(data);
        // console.log("email after getItem: ", data);

    }, []);
    useEffect(() => {
        window.sessionStorage.setItem("email", email)
    }, [email]);

    // console.log("hello!", email);
    // console.log("getItem", window.sessionStorage.getItem("email"));

    //To make sure that state is persistant on refresh.
    useEffect(() => {
        dispatch(setEmail(window.sessionStorage.getItem("email")));

    }, [email])

    //event handling
    const Next = (e) => {
        
        formik.handleSubmit()
    }
    const handleThis = (e) => {
        e.preventDefault()
        setEmailVal(e.target.value)
    }
    // console.log("email in local state is: ", email);

    const onBack = (e) => {
        e.preventDefault()
        Navigate(-1)
    }
    //sending state data to server
    const fullState=useSelector(state=>state)
    
    const sendAllData=()=>{
    //    console.log("my full state is : ", fullState);
       SendStateToCompare(fullState)
    }
    return (
        <Grid container
            direction="column"
            marginTop="60px"
        >
            <Box>
                <div className='back-btn' onClick={onBack}>
                    <IconButton  ><ArrowBackIos /></IconButton>
                </div>
                <Card sx={{
                    minHeight: '250px',
                    minWidth: '300px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: 'none',
                    backgroundColor: '#F7F7F7'
                }} >
                    <React.Fragment>
                        <CardContent >

                            <FormControl variant='filled' className='centerMyForm' >
                                <Typography
                                    component="div"
                                    variant='h3'
                                    sx={{
                                        fontWeight: 500,
                                        fontFamily: "Merriweather  serif",
                                        fontSize: '2.1rem',
                                        lineHeight: '36px',
                                        textAlign: 'center',
                                        marginBottom: "30px"


                                    }}
                                >
                                    We hebben je e-mailadres nodig voordat we aanbieders tonen.
                                </Typography>
                                <TextField
                                    className='inputSt'
                                    required
                                    variant='filled'
                                    id='email'
                                    label='e-mailadres'
                                    placeholder="example@email.io"
                                    sx={{ width: "320px", marginBottom:"10px" }}
                                    data-testid="email"

                                    value={formik.values.email}
                                    onInput={handleThis}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email ? formik.errors.email:""}

                                />
                                <br />
                                <div className='buttonbox'>
                                    <Button variant="contained" type='submit' size="large"
                                        className='button'
                                        data-testid="nextButton"
                                        onClick={() => {
                                            sendAllData();
                                            Next();
                                            
                                        }}  >Verder</Button>
                                </div>
                            </FormControl>
                        </CardContent>
                    </React.Fragment>
                </Card>
            </Box>
        </Grid>
    );

}