//React Tools
import * as React from "react";
import { useNavigate } from "react-router-dom";
//MUI tools
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
//styles
import logo from "../../assets/logo.png";
import "./Header.css";

export const Header = () => {
  const Navigate = useNavigate();

  return (
    <div>
      <Grid container direction="row" justifyContent="center">
        <AppBar
          className="header"
          style={{
            borderBottom: "1px solid rgb(139, 213, 255)",
            display: "flex",
          }}
          position="relative"
          sx={{ bgcolor: "#F7F7F7", boxShadow: "none" }}
        >
          <Grid container direction="row" sx={{ alignItems: "center", width:"343px", marginLeft:"16px" }}>
            {/* <Grid
              sx={{
                color: "#1aa9fa",
                fontWeight: "700",
                maxWidth: "150px !important",
                flexGrow: 1,

                textAlign: "center",
              }}
              item
              sm={4}
              md={4}
              lg={2}
            > */}
              <a href="../">
                <img className="logo-header" src={logo} alt="logo" />
              </a>
            </Grid>
          {/* </Grid> */}
        </AppBar>
        <div className="avatar">
          <Avatar
            className="avatar-span"
            sx={{ width: 55, height: 55 }}
            alt="Remy Sharp"
            src="/ton.png"
          />
        </div>
      </Grid>
    </div>
  );
};
export default Header;
