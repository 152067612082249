import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "./Step/Styles.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import younited from "../assets/younited.png";
import time from "../assets/time.png";
import stats from "../assets/stats.png";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import {
  setOffer,
  setSelectedLogo,
  setCredit,
  setMinL,
  setMaxL,
  setRate,
  setDuration,
} from "../services/Card/CardActions";

export const Provider = (props) => {
  const {
    logo,
    credit,
    minimum_loan,
    maximum_loan,
    loan_rate_indication,
    max_duration,
    ...rest
  } = props;

  const dispatch = useDispatch();

  //Selecting useful bits of card state:
  // const logo = useSelector(state => state.cardReducer.logo[0]);
  // const credit= useSelector(state=>state.cardReducer.Credit[0]);
  const [loan, setLoan] = useState("");
  const purpose = useSelector((state) => state.formInfo.Goals);

  const location = useLocation();
  const Navigate = useNavigate();
  let locState = "fromStep6";
  useEffect(() => {
    setLoan(window.sessionStorage.getItem("amount"));
  }, []);

  const Next = (e) => {
    dispatch(
      setOffer(
        logo,
        credit,
        minimum_loan,
        maximum_loan,
        loan_rate_indication,
        max_duration
      )
    );
    dispatch(setSelectedLogo(logo));
    dispatch(setCredit(credit));
    dispatch(setDuration(max_duration));
    dispatch(setMinL(minimum_loan));
    dispatch(setMaxL(maximum_loan));
    dispatch(setRate(loan_rate_indication));
    window.sessionStorage.setItem(
      "offer",
      JSON.stringify({
        logo,
        credit,
        minimum_loan,
        maximum_loan,
        loan_rate_indication,
        max_duration,
      })
    );

    Navigate("../step/8", { state: locState });
  };
  // console.log(location.state);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Card
        className="container"
        sx={{
          // minWidth: 500,
          maxWidth: 700,
          minHeight: 200,
          // display:'flex',
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "135px" }}>
          <CardMedia
            component="img"
            sx={{ width: 151 }}
            image={logo}
            alt="Younited credit"
            style={{
              width: "100px",
              marginLeft: "10px",
              marginBottom: "10px",
            }}
          />
          <Box
            sx={{
              border: 2,
              borderColor: "lightblue",
              borderRadius: 1,
              width: "120px",
            }}
          >
            <Typography
              component="div"
              variant="subtitle"
              fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
              sx={{
                marginLeft: "9px",
                marginTop: "6px",
                fontSize: 12,
                fontWeight: 600,
                maxWidth: "650px",
              }}
            >
              Lening:
            </Typography>
            <Typography
              component="div"
              variant="h6"
              alignSelf="center"
              justifyContent="center"
              fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
              sx={{
                margin: "0 20px 0 20px",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              € {loan}
            </Typography>
          </Box>
        </div>
        <div style={{ width: "200px", marginLeft: "9px" }}>
          <div style={{ height: "50px" }}>
            <Typography
              component="div"
              variant="subtitle"
              fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
              sx={{
                fontSize: 20,
                position: "absolute",
                marginTop: "5px",
              }}
            >
              <strong>Zakelijke lening</strong> <br /> {credit}
            </Typography>
          </div>
          <div>
            <Typography
              component="div"
              variant="subtitle"
              color="#2E353C"
              sx={{ fontSize: 13, marginTop: "3px" }}
            >
              <strong
                style={{
                  fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                }}
              >
                minimale lening:
              </strong>
              € {minimum_loan}
            </Typography>
          </div>
          <div>
            <Typography
              component="div"
              variant="subtitle"
              color="#2E353C"
              sx={{ fontSize: 13, marginTop: "3px" }}
            >
              <strong
                style={{
                  fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                }}
              >
                maximale lening:
              </strong>
              € {maximum_loan}
            </Typography>
          </div>
          <div>
            <Typography
              component="div"
              variant="subtitle"
              color="#2E353C"
              sx={{ fontSize: 13, marginTop: "3px" }}
            >
              <strong
                style={{
                  fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                }}
              >
                rente p.j.{" "}
              </strong>{" "}
              {loan_rate_indication}
            </Typography>
          </div>
        </div>
        <div style={{ width: "210px", margin: "auto" }}>
          <div style={{ display: "inline-flex" }}>
            <div
              style={{ marginRight: "10px", display: "flex", marginTop: "6px" }}
            >
              <img style={{ marginTop: "5px" }} src={time} alt="time icon" />
              &nbsp;
              <div>
                <Typography
                  component="div"
                  variant="subtitle"
                  fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                  sx={{
                    marginTop: "12px",

                    fontSize: 14,
                  }}
                >
                  <strong> Maximale looptijd: </strong> {max_duration} jaar
                </Typography>
              </div>
            </div>
          </div>

          <div>
            <Button
              sx={{
                marginLeft: "10px",
                alignItems: "center",
                marginTop: "10px",
                marginBottom: "7px",
                marginRight: "10px",
                justifyContent: "10px",
                width: "90%",
              }}
              variant="contained"
              data-testid="offer"
              onClick={Next}
            >
              Offerte
            </Button>
          </div>
          <Typography
            component="div"
            variant="h6"
            fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
            sx={{
              color: "#1976D2",
              marginLeft: "15px",
              fontSize: 13,
            }}
          >
            Zonder verdere verplichtingen
          </Typography>
        </div>
      </Card>
      <br />
    </Grid>
  );
};
