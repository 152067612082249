//importing Action Types
import { CardActionTypes } from "./cardActionTypes";


const initialState = {
    //Card State:
    credit: "",
    duration: '',
    selectedLogo: '',
    rate: "",
    maxLoan: "",
    minLoan: "",

    offers: []
}

export const CardReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CardActionTypes.SET_CREDIT:
            return { ...state, Credit: payload };
        case CardActionTypes.SET_PROPOSED_LOAN:
            return { ...state, loan: payload };
        case CardActionTypes.SET_DISPENSING:
            return { ...state, dispensing: payload };
        case CardActionTypes.SET_DURATION:
            return { ...state, duration: payload };
        case CardActionTypes.SET_TAN:
            return { ...state, TAN: payload };
        case CardActionTypes.SET_TAEG:
            return { ...state, TAEG: payload };
        case CardActionTypes.SET_INIT:
            return { ...state, InitCharges: payload };
        case CardActionTypes.SET_AMOUNTD:
            return { ...state, amountDistributed: payload };
        case CardActionTypes.SET_FINANCE:
            return { ...state, financeability: payload };
        case CardActionTypes.SET_MANAGE:
            return { ...state, management: payload };
        case CardActionTypes.SET_TOTAL:
            return { ...state, total: payload };
        case CardActionTypes.SET_SELECTEDLOGO:
            return { ...state, selectedLogo: payload };
        case CardActionTypes.SET_SELECTEDINFO:
            return { ...state, offers: [...state.offers, payload] };
        case CardActionTypes.SET_RATE:
            return { ...state, rate: payload };
        case CardActionTypes.SET_MAXL:
            return { ...state, maxLoan: payload };
        case CardActionTypes.SET_MINL:
            return { ...state, minLoan: payload };
        default:
            return state;

    }
}