//React
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
//MUI
import { TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';
//Form builders & validators
import { useFormik } from 'formik';
import * as yup from 'yup';
//Actions
import { getLimits, setTurnover } from '../../services/Form/FormActions';
//Styles
import './Styles.css';

export const TurnOver = () => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [turno, setTurno] = useState();
    const [error, setError] = useState("");

    //navigation
    const location = useLocation();
    console.log(location.state);
    useEffect(() => {
        if (location.state !== 'fromStep2') {
            //Should only be accessible from previous step 
            Navigate(-1)
        }
    }, []);

    let locState = "fromTurnOver"
    //What if the user's turnover is less than required?
    const compareTurnover = async (turnover) => {
        let res = await getLimits()
        if (res.minTo > turnover) {
            return "Je omzet is te laag."
        }
        else { return "" }
    }

    //form building and validators
    const validationSchema = yup.object().shape({
        turno: yup
            .number().typeError('Vul een getal in.')
            .required('We hebben je omzet nodig.')
    })
    const formik = useFormik({
        initialValues: {
            turno,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(setTurnover(turno));
            Navigate("../step/4", { state: locState })
        },
    })
    //State Management:
    useEffect(() => {
        const data = window.sessionStorage.getItem("turno");
        formik.setFieldValue("turno", data&&data!=="null" ?data:"")
        setTurno(data);

    }, []);
    useEffect(() => {
        window.sessionStorage.setItem("turno", turno)
    }, [turno]);


    //To make sure that state is persistant on refresh.
    useEffect(() => {
        dispatch(setTurnover(window.sessionStorage.getItem("turno")));

    }, [turno])
    //handling events
    const Next = async (e) => {
        e.preventDefault()
        let error = await compareTurnover(turno);
        if (error) {
            setError(error)
        }
        else {
            formik.handleSubmit()
        }
        
    }

    const handleThis = (e) => {
        e.preventDefault()
        setTurno(e.target.value)
    }

    const onBack = (e) => {
        e.preventDefault()
        Navigate(-1)
    }
    return (
        <Grid container
            direction="column"
            width="100%"
            marginTop="60px"
        >

            <Box >
                <div className='back-btn' onClick={onBack}>
                    <IconButton  ><ArrowBackIos /></IconButton>
                </div>
                <Card className='bod' sx={{ minHeight: '250px', minWidth: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: 'none', backgroundColor: '#F7F7F7' }} >
                    <React.Fragment>

                        <CardContent>

                            <FormControl className='centerMyForm'>
                                <Typography
                                    component="div"
                                    variant='h3'
                                    sx={{
                                        fontWeight: 500,
                                        fontFamily: "Merriweather  serif",
                                        fontSize: '2.1rem',
                                        lineHeight: '36px',
                                        textAlign: 'center',
                                        marginBottom: "25px"
                                    }}
                                >
                                     Hoe hoog was je omzet vorig jaar?
                                </Typography>
                                <Grid
                                    container
                                    direction="column"
                                    width="320px"
                                    justifyContent="center"
                                    alignItems="center">
                                    <TextField
                                        id='turno'
                                        name='turno'
                                        label="Omzet"
                                        variant='filled'
                                        sx={{ width: "320px" }}
                                        data-testid="turno"



                                        value={formik.values.turno}
                                        onInput={handleThis}

                                        onChange={formik.handleChange}
                                        error={error ? true : formik.touched.turno && Boolean(formik.errors.turno)}
                                        helperText={error ? error : formik.touched.turno && formik.errors.turno}
                                    />

                                </Grid> <br />
                                <div className='buttonbox'>
                                    <Button variant="contained"
                                        type='submit'
                                        size="large"
                                        className='button'
                                        data-testid="nextButton"
                                        //disabled={!document.getElementById('company').value}
                                        onClick={Next}  >Verder</Button>
                                </div>
                            </FormControl>
                        </CardContent>
                    </React.Fragment>
                </Card>
            </Box>
        </Grid>
    );
} 