//React
import React from 'react';
import { useNavigate } from 'react-router-dom';
//MUI tools
import Button from '@mui/material/Button';
import { CardMedia, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
//styles
import logo from '../../assets/logo.png'
import './Styles.css';
import business from '../../assets/firstpic.jpg';


export const Home = () => {

    const Navigate = useNavigate();

    const login = (e) => {
        e.preventDefault()
        Navigate("../login")
    }

    const Next = (e) => {
        e.preventDefault()
        Navigate("../step/1")
    }
    return (
        <Grid
            container
            direction="column">
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" style={{ background: "#F7F7F7", boxShadow: "none", borderBottom: '1px solid rgb(139, 213, 255)' }}>
                    <Toolbar>
                        <Box sx={{ flexGrow: 1 }}>
                            <div></div>
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <a href="../">
                                <img className='logo-header' src={logo} alt="logo" />
                            </a>
                        </Box>
                        <Button sx={{fontSize:"11px"}} variant='outlined' onClick={login}>Inloggen</Button>
                    </Toolbar>
                </AppBar>
            </Box>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                marginTop="60px"


            >
                <Typography
                    component="div"
                    variant='h3'
                    sx={{
                        fontWeight: 500,
                        fontFamily: "Merriweather  serif",
                        fontSize: '2.1rem',
                        lineHeight: '36px',
                        textAlign: 'center'


                    }}
                >
                    Makkelijk online een zakelijke lening regelen tot en met 2 miljoen.
                </Typography>
                <Typography
                    component="div"
                    variant='h4'
                    color='#34393D'
                    maxWidth="600px"
                    sx={{
                        fontWeight: 400,
                        fontFamily: "Merriweather  serif",
                        fontSize: '1.5rem',
                        lineHeight: '36px',
                        textAlign: 'center',
                        


                    }}
                >
                    Vul een kort formulier in, zonder enige verplichtingen. 
                    We sturen geen gegevens door zonder jouw toestemming.                
                </Typography>
                <Card
                    sx={{ marginTop: "30px", boxShadow: "none", backgroundColor: "#F7F7F7" }}
                >
                    <CardMedia
                        className='startimg'
                        component="img"
                        image={business}
                        alt="business logo img"

                    />

                </Card>
                <Typography
                    component="div"
                    variant='h4'
                    color='#34393D'
                    maxWidth="600px"
                    sx={{
                        fontWeight: 300,
                        fontFamily: "Merriweather  serif",
                        fontSize: '1.4rem',
                        lineHeight: '36px',
                        textAlign: 'center',
                        
                        


                    }}
                >
                    Krijg binnen 24 uur resultaten van enkele aanbieders, zodat je snel weet waar je aan toe bent.                
                </Typography>
                <Button

                    variant="contained"
                    type='submit'
                    size='large'
                    className='button'
                    sx={{ marginTop: "15px", marginBottom: "15px" }}
                    onClick={Next}
                >
                    Aanvragen
                </Button>

            </Grid>
        </Grid>
    );
}