//Importing Action types:
import axios from "axios";
import { FormActionTypes } from "./formActionTypes";
const baseUrl=process.env.REACT_APP_SERVER_URL

//Actions or Events:
export const setLoan = (loan) => {
    return {
        type: FormActionTypes.SET_LOAN,
        payload: loan
    };
};

export const setCompany = (company) => {
    return {
        type: FormActionTypes.SET_COMPANY,
        payload: company
    };
};
export const setTurnover=(turnover)=>{
    return {
        type: FormActionTypes.SET_TURNOVER,
        payload: turnover
    };
};

export const setGoals = (goals) => {
    return {
        type: FormActionTypes.SET_GOALS,
        payload: goals
    };
};
export const setClients = (clients) => {
    return {
        type: FormActionTypes.SET_CLIENTS,
        payload: clients
    };
};

export const setEmail = (email) => {
    return {
        type: FormActionTypes.SET_EMAIL,
        payload: email
    };
};

export const setName = (name) => {
    return {
        type: FormActionTypes.SET_NAME,
        payload: name
    };
};

export const setSurname = (surname) => {
    return {
        type: FormActionTypes.SET_SURNAME,
        payload: surname
    };
};

export const setPhone = (phone) => {
    return {
        type: FormActionTypes.SET_PHONE,
        payload: phone
    };
};
export const setKvk=(kvk)=>{
    return {
        type: FormActionTypes.LOAD_KVK,
        payload: kvk
    }
}
export const getCompanyData =async  (textkvk, dispatch) => {
    try {
        let res= await axios.get(baseUrl+'/companies/get-company-data/' + textkvk) //react base url + /get...
        dispatch({
                    type: FormActionTypes.GET_COMPANY_DATA,
                    payload: setKvk(res.data.body),
                    
                })
                // console.log("success");

                return {
                    type: FormActionTypes.GET_COMPANY_DATA,
                    payload: setKvk(res.data.body), 
                }
    } catch (error) {
        dispatch({
            type: "Error",
            payload: console.log(error)
        })
        console.log("something went wrong");
    }
        
};
export const loadKvk = async (text, apiKey) => {
    try {
        let res = await axios.get(`https://api.overheid.io/suggest/openkvk/${text}?size=10&ovio-api-key=${apiKey}`)
        console.clear();
        return res.data
        
    } 
    catch (error) {
        if (error.response && error.response.status === 500) {console.clear();
        }
        throw new Error ("something went wrong")
    }
}
export const SendStateToCompare=async (state)=>{
    try {
        let res = await axios.post(baseUrl+'/companies/data-to-compare', state)
        // console.log(res.data);
    } catch (error) {
            console.log("error", error);
    }
}
export const getFilteredProductsAge= async(age)=>{
    try {
        let res = await axios.post(baseUrl+'/products/filter-age',{age})
        return res.data;

    } catch (error) {
        if(error.response.status==406) {
            // console.log(error.response.data);
            let res=error.response.data[0]
            
            throw res
        }
        
        else{console.log("something happened!", error);}
    }
}
export const getFilteredProducts= async()=>{
    try {
        let res = await axios.get(baseUrl+'/products/filter')
        return res.data;

    } catch (error) {
        if(error.response.status==406) {
            // console.log(error.response.data);
            let res=error.response.data[0]
            
            throw res
        }
        
        else{console.log("something happened!", error);}
    }
}

export const SendfullState=async (state)=>{
    try {
        let res = await axios.post(baseUrl+'/companies/all-company-data', state)
        // console.log(res.data);
    } catch (error) {
            console.log("error", error);
    }
}
export const getLimits=async ()=>{
    try {
        let res=await axios.get(baseUrl+'/products/limits')
        // console.log(res.data[0]);
        return res.data[0]
    } catch (error) {
        console.log(error);
    }

}