import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { TextField, Typography } from '@mui/material';
import './Styles.css';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import InputAdornment from '@mui/material/InputAdornment';
import PhoneIcon from '@mui/icons-material/Phone';
import { useFormik } from 'formik';
import * as yup from 'yup';

export const Login = () => {

    const Navigate = useNavigate();

    const validationSchema = yup.object({
        email: yup
            .string()
            .email('Vul een geldig e-mailadres in.')
            .required('We hebben je e-mailadres nodig.'),
        password: yup
            .string('')
            .min(8, 'Je wachtwoord moet bestaan uit minimaal 8 karakters.')
            .required('Vul je wachtwoord in.')
    })
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

        },
    })

    const login = (e) => {
        e.preventDefault()
        formik.handleSubmit()
    }
    const onBack = (e) => {
        e.preventDefault()
        Navigate("../")
    }

    return (
        <Grid container
            direction="column"
            // alignItems="center"
            // justifyContent="center"
            marginTop="60px"
        >
            <Box>
                <div className='back-btn' onClick={onBack}>
                    <IconButton  ><ArrowBackIos /></IconButton>
                </div>
                <Card sx={{
                    minHeight: '250px',
                    minWidth: '300px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: 'none',
                    backgroundColor: '#F7F7F7'
                }} >
                    <React.Fragment>
                        <CardContent >

                            <FormControl variant='filled' className='centerMyForm' >
                                <Typography
                                    component="div"
                                    variant='h3'
                                    sx={{
                                        fontWeight: 500,
                                        fontFamily: "Merriweather  serif",
                                        fontSize: '2.1rem',
                                        lineHeight: '36px',
                                        textAlign: 'center',
                                        marginBottom: "30px"


                                    }}
                                >
                                    Gebruik je e-mailadres om in te loggen.
                                </Typography>
                                <TextField
                                    required
                                    variant='filled'
                                    id='email'
                                    placeholder="example@email.io"
                                    label='e-mailadres'
                                    sx={{ width: "320px" }}

                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}


                                />
                                <br />
                                <Typography
                                    component="div"
                                    variant='h3'
                                    sx={{
                                        fontWeight: 500,
                                        fontFamily: "Merriweather  serif",
                                        fontSize: '2.1rem',
                                        lineHeight: '36px',
                                        textAlign: 'center',
                                        marginBottom: "30px"


                                    }}
                                >
                                    Vul je wachtwoord in.
                                </Typography>
                                <TextField
                                    required
                                    variant='filled'
                                    id='password'
                                    placeholder="wachtwoord"
                                    sx={{ width: "320px" }}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                /> <br /> <br />
                                <div className='buttonbox'>
                                    <Button variant="contained" type='submit' size="large" onClick={login} className='button'
                                    >Login</Button>
                                </div>
                            </FormControl>
                        </CardContent>
                    </React.Fragment>
                </Card>
            </Box>
        </Grid>
    );
}